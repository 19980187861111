<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout wrap>
      <v-flex md12>
        <material-card
          :title="$t('common.search')"
          color="green"
          flat
          full-width
          class="bg-white"
        >
          <v-form>
            <v-container py-0>
              <v-layout wrap>
                <v-flex
                  xs12
                  md4>
                  <v-text-field
                    v-model="search.voucherSerial"
                    :label="$t('evoucher.voucherSerial')"
                    @keyup.enter="lookupVoucherInfoList"
                  />
                </v-flex>
                <v-flex
                  xs12
                  md4>
                  <v-text-field
                    v-model="search.email"
                    :label="$t('customer.email')"
                    @keyup.enter="lookupVoucherInfoList"
                  />
                </v-flex>
                <v-flex
                  xs12
                  md4>
                  <v-text-field
                    v-model="search.phone"
                    :label="$t('customer.phone')"
                    type="text"
                    @keyup.enter="lookupVoucherInfoList"
                  />
                </v-flex>
              </v-layout>
              <v-btn
                style="margin-right: 0;"
                class="pull-right"
                color="success"
                @click="lookupVoucherInfoList"
              >
                <span class="btn-label mr-3">
                  <i class="glyphicon glyphicon-search" />
                </span>
                <span style="text-transform: none;">{{
                  $t("common.search")
                }}</span>
              </v-btn>
            </v-container>
          </v-form>
        </material-card>
        <!-- Receive list -->
        <material-card
          :title="$t('infoVoucher.list')"
          color="green"
          flat
          full-width
        >
          <div @mouseleave="$refs.tblVoucherList.expanded=[]">
            <v-data-table
              ref="tblVoucherList"
              :headers="voucherInfoHeaders"
              :items="voucherInfoList"
              :no-data-text="$t('common.noDataAvailable')"
              :expand="false"
              hide-actions
            >
              <template
                slot="headerCell"
                slot-scope="{ header }"
              >
                <span
                  class="text--darken-3 font-medium"
                  v-text="$t(header.text)"
                />
              </template>
              <template v-slot:items="props">
                <tr
                  :class="props.item.isLoading ? 'bg-1' : ''"
                  style="position: relative;"
                  @click="props.expanded=!props.expanded"
                  @mouseover="props.expanded=true">
                  <td
                    v-for="(header, index) in voucherInfoHeaders"
                    :key="header.value + index">
                    <span v-if="header.value == 'status'">
                      {{ $t(getVoucherStatusType(props.item[header.value])) }}
                    </span>
                    <span
                      v-else-if="header.value == 'voucherSerial'"
                      class="cursor-pointer"
                      style="border-bottom: 1px dashed #5cb860;"
                    >
                      <span v-if="!props.item.isLoading">{{ props.item[header.value] }}</span>
                      <div
                        v-else
                        class="text-xs-center">
                        <v-progress-circular
                          :value="40"
                          indeterminate
                          class="text-xs-center"
                          color="white"
                        />
                      </div>
                    </span>
                    <span v-else-if="header.value == 'action'">
                      <v-tooltip
                        v-if="props.item.status != VoucherStatusType.USED && props.item.status != VoucherStatusType.INACTIVE && !notExpiredYet(props.item)"
                        top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            :loading="props.item['resendVoucherInfoLoading']"
                            class="mx-2"
                            fab
                            small
                            color="success"
                            style="color: #ffffff;"
                            v-on="on"
                            @click="onShowModalConfirmResendVoucherInfo($event, props.item)"
                          >
                            <v-icon dark>mdi-email-send</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('infoVoucher.resendVoucherInfo') }}</span>
                      </v-tooltip>
                    </span>
                    <div v-else-if="header.value == 'customer'">
                      <div class="font-weight-bold">{{ props.item.customerName }}</div>
                      <div v-if="props.item.customerEmail">({{ props.item.customerEmail }})</div>
                    </div>
                    <span v-else>{{ props.item[header.value] }}</span>
                  </td>
                </tr>
              </template>
              <template v-slot:expand="props">
                <div
                  class="py-2 mx-3"
                  style="padding-left: 50px"
                  @mouseleave="props.expanded=false">
                  <div style="display: flex; white-space: nowrap; align-items: center;">
                    <action-item
                      v-if="hasAction('view_voucher') || isUsageSite()"
                      first
                      class="text-success"
                      @click="showPreviewVoucher($event, props.item.id)">
                      {{ $t('infoVoucher.voucherInformation') }}
                    </action-item>
                    <action-item
                      v-if="hasAction('view_history') || isAdmin() || isUsageSite()"
                      class="blue--text"
                      @click="viewHistoryTransaction($event, props.item.id)">
                      {{ $t('evoucher.historyTransaction') }}
                    </action-item>
                    <action-item
                      v-if="props.item.status == VoucherStatusType.ACTIVATED && (hasAction('manage_voucher') || isAdmin() || isUsageSite())"
                      class="red--text"
                      @click="enableDisableVoucher($event, props.item, VoucherStatusType.DISABLED)">
                      {{ $t('evoucher.disableVoucher') }}
                    </action-item>
                    <action-item
                      v-if="props.item.status == VoucherStatusType.DISABLED && (hasAction('manage_voucher') || isAdmin() || isUsageSite())"
                      class="red--text"
                      @click="enableDisableVoucher($event, props.item, VoucherStatusType.ACTIVATED)">
                      {{ $t('evoucher.enableVoucher') }}
                    </action-item>
                  </div>
                  <div
                    v-if="props.item.status == VoucherStatusType.ACTIVATED && ((isSupplier() && hasAction('confirm_using')) || isUsageSite())"
                    style="display: flex;"
                    class="text-xs-center">
                    <action-item
                      first
                      class="deep-purple--text lighten-1"
                      @click="onShowModalConfirmVoucher($event, props.item, 1)">
                      {{ $t('evoucher.directConfirmation') }}
                    </action-item>
                    <action-item
                      class="indigo--text lighten-3"
                      @click="onShowModalConfirmVoucher($event, props.item, 2)">
                      {{ $t('evoucher.confirmationFromCustomers') }}
                    </action-item>
                    <action-item
                      v-if="isSupplier()"
                      class="text-warning lighten-3"
                      @click="onShowModalExternExpiredDays($event, props.item)">
                      {{ $t('evoucher.issuance.extendExpiredDate') }}
                    </action-item>
                  </div>
                  <div
                    v-if="(props.item.status == VoucherStatusType.DISABLED || props.item.status == VoucherStatusType.ACTIVATED) && (isAdmin())"
                    style="display: flex;"
                    class="text-xs-center">
                    <action-item
                      first
                      class="deep-purple--text lighten-1"
                      @click="onShowModalTransferVoucherToOtherCustomer($event, props.item)">
                      {{ $t('evoucher.transferVoucher') }}
                    </action-item>
                  </div>
                </div>
              </template>
            </v-data-table>
          </div>
        </material-card>
        <pagination
          :total="paginate.totalPage"
          :current-page="paginate.currentPage"
          :row-per-page="paginate.rowPerPage"
          @onPageChange="onPageChange"
        />
      </v-flex>
    </v-layout>
    <loading-bar :is-loading="isLoading" />
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
    <confirm-modal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="confirmTypeSelected == confirmType.RESEND_VOUCHER_INFO ? onResendVoucherInfo() : confirmUsedVoucherFromCustomer()"
    />
    <historyTransactionVoucher ref="historyTransactionVoucher" />
    <e-voucher-preview-modal ref="voucherPreviewModal" />
    <confirmUsedVoucherDirectModal
      ref="confirmUsedVoucherDirectModal"
      @onConfirm="onConfirmUsedDirect" />
    <extend-expired-day-modal
      ref="extendExpiredDayModal"
      @onUpdated="onExtendExpiredDateSuccess()"/>
    <confirm-modal
      ref="enableDisableVoucherModal"
      :title="enableDisableVoucherTitle"
      @onConfirm="confirmEnableDisableVoucher"
    />
    <transferVoucherToOtherCustomerModal
      ref="transferVoucherToOtherCustomerModal"
      @onConfirm="confirmTransferVoucherToOtherCustomer"/>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import dateUtils from 'utils/dateUtils'
import VoucherStatusType from 'enum/voucherStatusType'
import functionUtils from 'utils/functionUtils'
import ToastType from 'enum/toastType'
import VoucherValue from 'enum/voucherValue'
import HistoryTransactionVoucher from './HistoryTransactionVoucher'
import EntityType from 'enum/entityType'
import ConfirmUsedVoucherDirectModal from './ConfirmUsedVoucherDirectModal'
import EVoucherPreviewModal from 'Views/EVoucher/EVoucherPreviewModal'
// import stringUtils from 'utils/stringUtils'
import moment from 'moment'
import ActionItem from 'Components/ActionItem.vue'
import ExtendExpiredDayModal from 'Views/EVoucher/ExtendExpiredDayModal.vue'
import TransferVoucherToOtherCustomerModal from './TransferVoucherToOtherCustomerModal'

export default {
  components: {
    HistoryTransactionVoucher,
    ConfirmUsedVoucherDirectModal,
    EVoucherPreviewModal,
    ExtendExpiredDayModal,
    ActionItem,
    TransferVoucherToOtherCustomerModal
  },
  data: () => ({
    voucherInfoHeaders: [
      { text: 'common.nonumber', value: 'stt' },
      { text: 'evoucher.voucherSerial', value: 'voucherSerial' },
      { text: 'evoucher.issuance.pricePercent', value: 'value' },
      { text: 'entity.supplier', value: 'supplier' },
      { text: 'evoucher.issuance.workpiece', value: 'voucherName' },
      { text: 'createVoucher.receivedDate', value: 'deliveredDate' },
      { text: 'createVoucher.endDateOfUse', value: 'expiredDate' },
      { text: 'evoucher.customer', value: 'customer' },
      { text: 'user.status', value: 'status' },
      { text: 'common.actions', value: 'action' }
    ],
    voucherInfoList: [],
    paginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    search: {
      voucherSerial: '',
      email: '',
      phone: ''
    },
    isLoading: false,
    VoucherStatusType: VoucherStatusType,
    voucherInfoSelected: null,
    confirmModalTitle: '',
    confirmType: {
      CONFIRM_DIRECT_USED: 1,
      RESEND_VOUCHER_INFO: 2
    },
    confirmTypeSelected: null,
    enableDisableVoucherTitle: '',
    voucherSelectId: null,
    enableDisableVoucherValue: null
  }),
  computed: {
    ...mapGetters([
      'VOUCHER_LOOKUP_INFO_DATA',
      'GET_SNACK_BAR',
      'GET_CURRENT_ACCOUNT'
    ])
  },
  watch: {
    VOUCHER_LOOKUP_INFO_DATA () {
      let res = this.VOUCHER_LOOKUP_INFO_DATA
      // Handle paginate
      this.paginate.currentPage = res.paginate.currentPage
      this.paginate.totalPage = res.paginate.totalPage
      this.paginate.rowPerPage = res.paginate.perPage / 2
      // Handle data
      let data = res.results
      this.voucherInfoList = []
      for (let i = 0, size = data.length; i < size; i++) {
        let voucherInfoObj = {
          stt: i + 1 + (res.paginate.currentPage - 1) * res.paginate.pageSize,
          voucherSerial: data[i].serial,
          workpiece: data[i].workpiece,
          value: data[i].workpiece.type === VoucherValue.PRICE ? functionUtils.convertFormatNumberToMoney(data[i].price) : (data[i].price + '%'),
          supplier: data[i].supplierName,
          voucherName: data[i].workpiece.name,
          deliveredDate: dateUtils.formatBeautyDate(data[i].deliveredDate),
          expiredDate: dateUtils.formatBeautyDate(data[i].expiredDate),
          status: data[i].status,
          id: data[i].id,
          resendVoucherInfoLoading: false,
          isLoading: false,
          customerName: functionUtils.concatFullname(data[i].customer.last_name, data[i].customer.first_name),
          customerEmail: data[i].customer.email
        }
        this.voucherInfoList.push(voucherInfoObj)
      }
      this.isLoading = false
    }
  },
  created () {
    this.lookupVoucherInfoList()
  },
  methods: {
    /**
     * Not expired yet
     */
    notExpiredYet: function (item) {
      let currentDate = moment().format(dateUtils.STATIC_FORMAT_DATE_SQL)
      let endDate = dateUtils.formatCompareDate(item.expiredDate, null)
      if (currentDate > endDate && !functionUtils.isNull(item.expiredDate)) {
        return true
      }
      return false
    },
    /**
     * Confirm used voucher
     */
    confirmUsedVoucherFromCustomer: function () {
      this.itemVoucher.isLoading = true
      let filter = {
        voucherId: this.itemVoucher.id,
        fromEntityType: EntityType.SUPPLIER
      }
      this.REQUEST_CONFIRM_VOUCHER_USED_FROM_CUSTOMER(filter)
        .then(
          function () {
            this.ON_SHOW_TOAST({
              message: this.$t('common.success'),
              styleType: ToastType.SUCCESS
            })
            this.lookupVoucherInfoList()
          }.bind(this)
        )
        .catch(
          function (error) {
            this.itemVoucher.isLoading = false
            if (!functionUtils.isNull(error.response.data.errorText)) {
              let errorText = error.response.data.errorText
              this.ON_SHOW_TOAST({
                message: this.$t(`${errorText}`),
                styletype: ToastType.ERROR
              })
            } else {
              this.ON_SHOW_TOAST({
                message: this.$t('login.somethingWentWrong'),
                styleType: ToastType.ERROR
              })
            }
          }.bind(this)
        )
    },
    /**
     * Confirm used direct voucher
     */
    onConfirmUsedDirect: function (activeCode, usedPrice, discountPrice, usedInfo) {
      let filter = {
        voucherId: this.itemVoucher.id,
        status: this.itemId === 1 ? VoucherStatusType.USED : VoucherStatusType.ACTIVATED,
        fromEntityType: EntityType.SUPPLIER,
        activeCode: activeCode,
        usedPrice: usedPrice,
        discountPrice: discountPrice,
        usedInfo: usedInfo
      }
      this.CONFIRM_VOUCHER_ASSIGNED(filter)
        .then(
          function () {
            this.ON_SHOW_TOAST({
              message: this.$t('common.success'),
              styleType: ToastType.SUCCESS
            })
            this.$refs.confirmUsedVoucherDirectModal.onCloseModal()
            this.lookupVoucherInfoList()
          }.bind(this)
        )
        .catch(
          function (error) {
            this.itemVoucher.isLoading = false
            if (!functionUtils.isNull(error.response.data.errorText)) {
              let errorText = error.response.data.errorText
              this.ON_SHOW_TOAST({
                message: this.$t(`${errorText}`),
                styletype: ToastType.ERROR
              })
            } else {
              this.ON_SHOW_TOAST({
                message: this.$t('login.somethingWentWrong'),
                styleType: ToastType.ERROR
              })
            }
            this.$refs.confirmUsedVoucherDirectModal.onConfirmFailed()
          }.bind(this)
        )
    },
    onShowModalExternExpiredDays: function (event, itemVoucher) {
      event.preventDefault()
      this.$refs.extendExpiredDayModal.onShowModal([itemVoucher.id], null, null)
    },
    /**
     * Show modal confirm voucher
     */
    onShowModalConfirmVoucher: function (event, itemVoucher, itemId) {
      event.preventDefault()
      this.itemId = itemId
      this.itemVoucher = itemVoucher
      let expiredDate = this.itemVoucher.expiredDate
      expiredDate = dateUtils.formatCompareDate(expiredDate)
      let currentDate = moment().format(dateUtils.STATIC_FORMAT_DATE_SQL)
      if (currentDate > expiredDate) {
        this.itemVoucher.isLoading = false
        this.ON_SHOW_TOAST({
          message: this.$t('evoucher.voucherHasExpired'),
          styleType: ToastType.ERROR
        })
      } else if (itemId === 1) {
        this.$refs.confirmUsedVoucherDirectModal.onShowModal(itemVoucher)
      } else {
        this.confirmTypeSelected = this.confirmType.CONFIRM_DIRECT_USED
        this.confirmModalTitle = this.$t('evoucher.issuance.doYouWantToConfirm')
        this.$refs.confirmModal.onShowModal()
      }
    },
    /**
     * Check is supplier
     */
    isSupplier: function () {
      return functionUtils.isEntityType(EntityType.SUPPLIER)
    },
    /**
     * Check has action
     */
    hasAction: function (metaAction) {
      return functionUtils.hasAction(this.GET_CURRENT_ACCOUNT, metaAction)
    },
    /**
     * Check is site
     */
    isUsageSite: function () {
      return functionUtils.isEntityType(EntityType.SITE)
    },
    /**
     * Check is super admin
     */
    isSuperAdmin: function () {
      return functionUtils.isSuperAdmin(this.GET_CURRENT_ACCOUNT)
    },
    // Check is admin
    isAdmin: function () {
      return functionUtils.isAdmin(this.GET_CURRENT_ACCOUNT)
    },
    /**
     * View history transaction
     */
    viewHistoryTransaction: function (event, voucherId) {
      event.preventDefault()
      this.$refs.historyTransactionVoucher.onShowModal(voucherId)
    },
    showPreviewVoucher: function (event, voucherId) {
      event.preventDefault()
      this.$refs.voucherPreviewModal.onShowModal(voucherId)
    },
    onExtendExpiredDateSuccess: function () {
      this.lookupVoucherInfoList()
    },
    /*
    * SHow modal confirm resend voucher info
    * */
    onShowModalConfirmResendVoucherInfo: function (event, voucherInfo) {
      event.preventDefault()
      this.confirmTypeSelected = this.confirmType.RESEND_VOUCHER_INFO
      this.confirmModalTitle = this.$t('evoucher.areYouSureSendThisVoucher')
      this.voucherInfoSelected = voucherInfo
      this.$refs.confirmModal.onShowModal()
    },
    /**
     * Resend voucher info
     */
    onResendVoucherInfo: function () {
      this.voucherInfoSelected.resendVoucherInfoLoading = true
      let filter = {
        voucherId: this.voucherInfoSelected.id
      }
      this.SEND_VOUCHER_INFO_TO_EMAIL(filter).then(
        function () {
          this.voucherInfoSelected.resendVoucherInfoLoading = false
          this.ON_SHOW_TOAST({
            message: this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
        }.bind(this)
      ).catch(
        function (error) {
          if (!functionUtils.isNull(error.response.data.errorText)) {
            let errorText = error.response.data.errorText
            this.ON_SHOW_TOAST({
              message: this.$t(`${errorText}`),
              styletype: ToastType.ERROR
            })
          } else {
            this.ON_SHOW_TOAST({
              message: this.$t('login.somethingWentWrong'),
              styleType: ToastType.ERROR
            })
          }
          this.voucherInfoSelected.resendVoucherInfoLoading = false
        }.bind(this)
      )
    },
    /**
     * Get e voucher status type
     */
    getVoucherStatusType: function (status) {
      return functionUtils.getVoucherStatusText(status)
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.paginate.currentPage = page
      this.lookupVoucherInfoList()
    },
    /**
     * Get voucher history list
     */
    lookupVoucherInfoList: function () {
      let filter = {
        params: {
          page: this.paginate.currentPage,
          voucherSerial: !functionUtils.isNull(this.search.voucherSerial) ? this.search.voucherSerial.trim() : null,
          email: !functionUtils.isNull(this.search.email) ? this.search.email.trim() : null,
          phone: !functionUtils.isNull(this.search.phone) ? this.search.phone.trim() : null
        }
      }
      this.isLoading = true
      this.LOOKUP_VOUCHER_INFO(filter).then(
        function () {
        }
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    enableDisableVoucher: function (event, eVoucherInfo, value) {
      event.preventDefault()
      let actionName = value === this.VoucherStatusType.ACTIVATED ? this.$t('evoucher.enableVoucher') : this.$t('evoucher.disableVoucher')
      this.enableDisableVoucherTitle = this.$t('evoucher.notiEnableDisable', { 'action': actionName, 'voucher': eVoucherInfo.voucherName })
      this.voucherSelectId = eVoucherInfo.id
      this.enableDisableVoucherValue = value
      this.$refs.enableDisableVoucherModal.onShowModal()
    },
    confirmEnableDisableVoucher: function () {
      let data = {
        voucherId: this.voucherSelectId,
        voucherStatus: this.enableDisableVoucherValue
      }
      this.isLoading = true
      this.ENABLE_OR_DISABLE_VOUCHER(data).then(
        function () {
          this.lookupVoucherInfoList()
          this.isLoading = false
        }
      ).catch(
        function () {
          this.lookupVoucherInfoList()
          this.isLoading = false
        }.bind(this)
      )
    },
    onShowModalTransferVoucherToOtherCustomer: function (event, itemVoucher) {
      event.preventDefault()
      this.$refs.transferVoucherToOtherCustomerModal.onShowModal(itemVoucher)
    },
    confirmTransferVoucherToOtherCustomer: function (data) {
    },
    ...mapActions([
      'LOOKUP_VOUCHER_INFO',
      'SEND_VOUCHER_INFO_TO_EMAIL',
      'ON_SHOW_TOAST',
      'CONFIRM_VOUCHER_ASSIGNED',
      'REQUEST_CONFIRM_VOUCHER_USED_FROM_CUSTOMER',
      'ENABLE_OR_DISABLE_VOUCHER'
    ])
  }
}
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer !important;
}
.bg-1 {
  animation: myBg 5s infinite;
}
@keyframes myBg {
  from {background-color: #E8F5E9;}
  to {background-color: #4CAF50;}
}
</style>
